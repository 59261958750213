.notfound
  font-size: 64px
  font-weight: bold
  text-align: center
.notfound-extended
  font-size: 14px
  text-align: center
.messages-wrapper
  display: block
  max-width: 100%
  max-height: 100%
  height: 100%
  overflow-y: scroll
  overflow-x: hidden
