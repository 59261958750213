@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-schedule/styles/material.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @keyframes emoji-float {
    0% {
      transform: translateY(0) scale(0.8);
      opacity: 0.8;
    }
    50% {
      transform: translateY(-50vh) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateY(-100vh) scale(0.8);
      opacity: 0;
    }
  }

  .animate-emoji-float {
    animation: emoji-float 7s ease-in-out infinite;
  }
}

/* a {
  color: inherit !important;
} */

body {
  pointer-events: auto !important;
}

/* * {
  scroll-behavior: smooth;
} */

a:hover {
  text-decoration: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-none::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.toaster {
  z-index: 99999999 !important;
}

.font-inter {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  /* font-weight: 500; */
  font-style: normal;
  font-variation-settings: 'slnt' 0;
}
.font-roboto-mono {
  font-family: 'Roboto Mono', serif;
  font-optical-sizing: auto;
}

.font-bricolage {
  font-family: 'Bricolage Grotesque', serif;
  font-optical-sizing: auto;
  font-style: normal;
}

#animated::after {
  transition: all 0.3s ease-in-out;
}

.quill {
  padding: 18px !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 12px !important;
  background: #ffffff !important;
}

/* .quill .ql-editor {
  min-height: 500px;
  max-height: 800px;
  overflow-y: auto;
} */

.ql-editor:focus {
  outline: none;
}

.ql-editor {
  border: none;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  padding: 6px 12px !important;
  width: max-content !important;
  border-radius: 9999px !important;
  background: #f0f0f0 !important;
  height: auto !important;
}
.ql-snow .ql-picker {
  height: auto !important;
}

.ql-picker-label {
  background: #ffffff !important;
  border-radius: 9999px !important;
  padding: 10px 14px !important;
  height: auto !important;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: 10px !important;
}

.ql-picker-options {
  background: #ffffff !important;
}

.ql-editor em {
  color: inherit !important;
}

.ql-formats button {
  color: #98a2b3 !important;
}

.ql-formats .ql-active {
  background: #ffffff !important;
  color: #101828 !important;
  border-radius: 9999px !important;
}

.ql-stroke {
  stroke: currentColor !important;
}

.ql-fill {
  fill: currentColor !important;
}

.ql-editor {
  font-size: 16px;
}

#brief-content em {
  color: inherit !important;
}

.tox-statusbar__text-container {
  /* display: none !important; */
  visibility: hidden !important;
}

.e-edit.e-control,
.e-delete.e-control {
  display: none !important;
}

.e-header-cells {
  text-align: center !important;
}

.e-schedule {
  font-family: 'Inter', sans-serif !important;
}
/* Customize toolbar and header cells */
.e-schedule .e-schedule-toolbar,
.e-schedule .e-header-cells,
.e-schedule .e-day,
.e-schedule .e-date-header,
.e-schedule .e-today,
.e-schedule .e-toolbar-item .e-control {
  color: #173300 !important;
  font-weight: 700 !important;
}
.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: #ffed74 !important;
  background-color: #173300 !important;
  padding: 2px 12px !important;
  border-radius: 20px;
}
.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text:hover,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons:hover {
  color: #ffed74 !important;
  background-color: #173300 !important;
  padding: 2px 12px !important;
  border-radius: 20px;
}
.e-toolbar .e-toolbar-item .e-tbar-btn:hover,
.e-toolbar .e-toolbar-item .e-tbar-btn {
  background: transparent !important;
}
.e-today .e-day,
.e-current-date .e-date-header {
  color: #ffed74 !important;
  background: #173300 !important;
  border-color: #173300 !important;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-subject {
  font-size: 10px !important;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-time,
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment .e-location {
  font-size: 9px !important;
}

.e-calendar .e-content td.e-selected span.e-day {
  background-color: #e9eef6 !important;
  color: #173300 !important;
}

#player,
#player video {
  background: #e9eef6 !important;
}

#player video {
  object-fit: cover;
}

.e-schedule .e-vertical-view .e-current-timeline {
  border: 2px solid #173300;
}

.e-schedule .e-vertical-view .e-previous-timeline {
  border: 2px dashed #173300;
  opacity: 0.2;
}

.e-schedule .e-vertical-view .e-clone-time-indicator,
.e-schedule .e-vertical-view .e-current-time {
  color: #173300;
}

.e-toolbar-item.e-today {
  display: none;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
  z-index: 10;
}

.e-schedule .e-toolbar-right {
  width: 200px;
  display: flex !important;
  justify-content: end !important;
}

#meetingDescription p {
  margin-bottom: 10px;
}
#meetingDescription br {
  margin-bottom: 10px;
}

#meetingDescription ol {
  list-style: decimal !important;
  padding-left: 20px;
  margin: 10px auto;
}
#meetingDescription ul {
  list-style: disc !important;
  padding-left: 20px;
  margin: 10px auto;
}

#highlights-content {
  /* padding: 1rem 0; */
  background-color: white;
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

#highlights-content h1 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: black;
  /* margin-bottom: 1.5rem; */
}
#highlights-content h2 {
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 600;
  color: black;
  /* margin-bottom: 1.5rem; */
}

#highlights-content h3 {
  font-size: 20px;
  line-height: 1.75rem;
  font-weight: 600;
  color: black;
  /* margin-bottom: 1rem;
  padding-bottom: 0.5rem; */
  /* border-bottom: 1px solid #e5e7eb; */
}
#highlights-content h4 {
  font-size: 16px;
  line-height: 1.75rem;
  font-weight: 600;
  color: black;
  /* margin-bottom: 1rem;
  padding-bottom: 0.5rem; */
  /* border-bottom: 1px solid #e5e7eb; */
}
#highlights-content p {
  font-size: 16px;
  line-height: 1.5rem;
  color: black;
  /* margin-bottom: 16px; */
}

#highlights-content strong {
  font-weight: 600;
}

#highlights-content ul {
  /* margin-top: -14px; */
  padding-left: 1.2rem;
  list-style: disc;
  /* margin-bottom: 14px; */
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#highlights-content ol {
  /* margin-top: -14px; */
  padding-left: 1.2rem;
  list-style: list-decimal;
  /* margin-bottom: 14px; */
  display: flex;
  flex-direction: column;
  gap: 16px;
}

#highlights-content li > p {
  margin-bottom: 12px;
}

#highlights-content li {
  color: #000000ce;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6rem;
}

#highlights-content ul ul {
  list-style: circle;
}
#highlights-content ol ul {
  list-style: lower-latin;
}

/* Quill Editor */

/* Custom styles for ReactQuill content */

.ql-toolbar.ql-snow {
  position: sticky;
  top: -20px;
  z-index: 10;
}

.ql-snow .ql-picker.ql-header {
  width: 110px;
}

.ql-container.ql-snow .ql-editor {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}

.ql-container.ql-snow .ql-editor h1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: black;
  margin-bottom: 20px;
}

.ql-container.ql-snow .ql-editor h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: black;
  margin-bottom: 20px;
}

.ql-container.ql-snow .ql-editor h3 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: black;
  padding-top: 16px;
  margin-bottom: 16px;
}

.ql-container.ql-snow .ql-editor h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: black;
  margin-bottom: 16px;
}

.ql-container.ql-snow .ql-editor p {
  font-size: 14px;
  line-height: 22px;
  color: black;
  margin-bottom: 16px;
}

.ql-container.ql-snow .ql-editor strong {
  font-weight: 600;
}

.ql-container.ql-snow .ql-editor ul {
  padding-left: 0;
  list-style: disc;
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ql-container.ql-snow .ql-editor ol {
  padding-left: 0;
  list-style: decimal;
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ql-container.ql-snow .ql-editor li > p {
  margin-bottom: 12px;
}

.ql-container.ql-snow .ql-editor li {
  color: #000000ce;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.ql-container.ql-snow .ql-editor ul ul {
  list-style: circle;
}

.ql-container.ql-snow .ql-editor ol ul {
  list-style: lower-latin;
}

.ql-container.ql-snow .ql-editor a {
  color: #007bff;
  text-decoration: none;
}

.ql-container.ql-snow .ql-editor a:hover {
  text-decoration: underline;
}

.ql-container.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin: 16px 0;
  padding-left: 16px;
  color: #666;
  font-style: italic;
}

.ql-container.ql-snow .ql-editor pre {
  background-color: #f5f5f5;
  padding: 16px;
  border-radius: 4px;
  font-family: 'Courier New', monospace;
  overflow-x: auto;
}

.ql-container.ql-snow .ql-editor code {
  background-color: #f5f5f5;
  padding: 3.2px 6.4px;
  border-radius: 3px;
  font-family: 'Courier New', monospace;
}

.ql-container.ql-snow .ql-editor img {
  max-width: 100%;
  height: auto;
  margin: 16px 0;
  border-radius: 4px;
}

.ql-container.ql-snow .ql-editor hr {
  border: 0;
  height: 1px;
  background: #e5e7eb;
  margin: 24px 0;
}

/* Default scrollbar style */
.sb-scrollbar {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #8e8e8e #f1f1f100; /* Thumb & track */
}

/* WebKit-based browsers (Chrome, Safari, Edge) */
.sb-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Scrollbar track */
.sb-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Scrollbar thumb */
.sb-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.sb-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Hide scrollbar but allow scrolling */
.sb-scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sb-scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

/* Dark Mode Scrollbar */
.sb-scrollbar-dark::-webkit-scrollbar-track {
  background: #222;
}

.sb-scrollbar-dark::-webkit-scrollbar-thumb {
  background: #555;
}

.sb-scrollbar-dark::-webkit-scrollbar-thumb:hover {
  background: #777;
}

/* React Tour */
.reactour__close-button {
  background: #173300 !important;
  color: #ffed74;
  border: none;
  padding: 8px !important;
  width: 26px !important;
  height: 26px !important;
  border-radius: 50%;
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  top: -12px !important;
  right: -12px !important;
}

.reactour__close-button svg {
  width: 18px !important;
  height: 18px !important;
}

.reactour__popover {
  border-radius: 8px !important;
  background-color: #fcfaf5 !important;
  top: -38px !important;
  left: 12px !important;
  max-width: 500px !important;
}

/* The triangular arrowhead using ::after */
.reactour__popover::after {
  content: '';
  position: absolute;
  left: -4%; /* Position it at the end of the rectangle */
  top: 27%;
  transform: rotate(180deg); /* Rotate it to point upwards */
  width: 0;
  height: 0;
  border-top: 12px solid transparent; /* Half of the rectangle height + border */
  border-bottom: 12px solid transparent; /* Half of the rectangle height + border */
  border-left: 20px solid #fcfaf5; /* Dark triangle to match the image */
}

.rmdp-arrow {
  border-color: #173300 !important;
  margin-top: 7px !important;
}

.rmdp-arrow-container:hover {
  background-color: #173300 !important;
}
.rmdp-arrow-container:hover i {
  border-color: #ffffff !important;
}

.rmdp-week-day {
  color: #173300 !important;
}

.rmdp-range {
  background-color: #173300 !important;
  box-shadow: none !important;
}

.rmdp-day.rmdp-today span{
  background-color: #173300 !important;
}
.rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover {
  background-color: #ffed74 !important;
  color: #173300 !important;
}

.rmdp-ep-arrow {
  display: none !important;
}
