.streams
  width: 100%
  height: 100%
  // background: black
  // background-image: url('/image/bg/meeting-bg.jpeg')
  flex-grow: 1

  .waiting
    color: #f7f7f7
    font-size: 28px
    font-weight: bold

  .video-container
    width: 95%
    height: 90%
    display: flex
    align-items: center
    overflow: auto
    gap: 20px
    .video-row
      width: 100%
      display: grid
      justify-content: center
      align-items: center
      gap: 20px
    .video-wrapper
      position: relative
      width: 100%
      height: 100%
    .remote-video
      position: absolute
      left: 0
      top: 0
      height: 100%
      width: 100%
      object-fit: cover
      // background: black
      z-index: 0
    .rotated
      transform: rotateY(180deg)
      -webkit-transform: rotateY(180deg)
      -moz-transform: rotateY(180deg)
