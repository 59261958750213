$panel-background: #fff
$nav-background: #fff

.messages-wrapper
  max-width: 100%
  flex-grow: 1
  height: 100%
  display: flex
  justify-content: center
  .messages-container
    flex-grow: 1
    max-width: 1000px
    display: block
  .message
    flex-grow: 1
    display: flex
    flex-direction: row
    padding: 0px 30px 10px 30px

    .picture img
      width: 2.5rem
      height: 2.5rem
      border-radius: 30px

    .picture .img
      width: 2.5rem
      height: 2.5rem
      border-radius: 30px
      display: flex
      justify-content: center
      align-items: center
      background: #666
      color: #f7f7f8
      font-size: 20px

    .content-x
      display: flex
      flex-direction: column
      min-width: 300px
      max-width: 30%

      .emoji-bubble
        position: relative
        margin: -2px 14px 0 14px
        font-size: 36px

      .emoji-bubble.right, .bubble-image.right
        text-align: right

      .bubble-image
        position: relative
        margin: -2px 14px 0 14px
        font-size: 14px
        cursor: pointer
        border-radius: 10px !important
        overflow: hidden
        width: 270px
        height: 270px
        min-height: 270px
        border: 1px solid #e3e7e7

        img
          width: 100%
          height: 100%
          object-fit: cover

      .bubble
        background: #ffffff
        
        color: #9fadb8
        margin: 0 14px
        padding: 8px 16px
        border-radius: 10px
        position: relative
        word-break: break-word

      .bubble.right
        background: #000000
        color: #ffffff

      .bubble-right:before
        content: ""
        width: 0
        height: 0
        position: absolute
        border-left: 10px solid #000000
        border-right: 15px solid transparent
        border-top: 26px solid #000000
        border-bottom: 15px solid transparent
        border-radius: 0 5px 0 0
        right: -10px
        top: 0

      .bubble-left:before
        content: ""
        width: 0
        height: 0
        position: absolute
        border-left: 15px solid transparent
        border-right: 10px solid #ffffff
        border-top: 26px solid #ffffff
        border-bottom: 15px solid transparent
        border-radius: 5px 0 0 0
        left: -10px
        top: 0

      .bubble-image:before
        border: 0 !important

      .message-details
        color: #9fadb8
        font-size: 10px
        background: transparent
        border: 0
        margin: 0 14px
        padding: 5px

      .message-details.right
        text-align: right
        width: 270px

  .message.right
    flex-direction: row-reverse
    justify-content: flex-start

div#wave
  position: relative
  text-align: center
  margin-left: auto
  margin-right: auto
  display: flex
  width: 100px
  .dot
    display: block
    width: 5px
    height: 5px
    border-radius: 50%
    margin-right: 5px
    background: #303131
    animation: wave 1.3s linear infinite

    &:nth-child(2)
      animation-delay: -1.1s

    &:nth-child(3)
      animation-delay: -0.9s

@keyframes wave
  0%, 60%, 100%
    transform: initial
  30%
    transform: translateY(-4px)
