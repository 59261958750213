.join
  width: 360px
  max-width: calc(100% - 80px)
  border: 1px solid #ccc
  padding: 20px
  background: white

  .logo-little
    height: 50px
    object-fit: contain
    margin-bottom: -30px

  .local-video
    width: 200px
    height: 113px
    background: black
    margin: 12px 0 20px 0
    object-fit: contain
    transform: rotateY(180deg)
    -webkit-transform: rotateY(180deg) /* Safari and Chrome */
    -moz-transform: rotateY(180deg) /* Firefox */

  .button
    margin-top: 20px
    background: #da7d02
    cursor: pointer

  .title
    font-size: 18px
    font-weight: bold
    text-transform: uppercase
    margin-bottom: 0

  .controls
    display: flex
    flex-direction: row
    align-items: center
    padding: 0 18px

    .icon
      font-size: 18px
      margin: 0 8px

.switch
  position: relative
  display: inline-block
  width: 60px
  height: 34px

.switch input
  opacity: 0
  width: 0
  height: 0

.slider
  position: absolute
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: #ccc
  -webkit-transition: .4s
  transition: .4s

.slider:before
  position: absolute
  content: ""
  height: 26px
  width: 26px
  left: 4px
  bottom: 4px
  background-color: white
  -webkit-transition: .4s
  transition: .4s

input:checked + .slider
  background-color: #da7d02

input:focus + .slider
  box-shadow: 0 0 1px #da7d02

input:checked + .slider:before
  -webkit-transform: translateX(26px)
  -ms-transform: translateX(26px)
  transform: translateX(26px)

/* Rounded sliders */
.slider.round
  border-radius: 34px

.slider.round:before
  border-radius: 50%
