.little-interface

  .remote-video
    width: 100%
    height: 100%

  .remote-peer
    background: #f7f7f7
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    flex-grow: 1
    width: 100%
    height: 100%
    overflow: hidden

    .name
      display: flex
      justify-content: center
      align-items: flex-end
      height: 30px
      font-size: 11px
      font-weight: bold
      color: #666
      padding: 1px 0

    .img
      display: flex
      width: 40px
      height: 40px
      min-width: 40px
      min-height: 40px
      border-radius: 75px
      justify-content: center
      align-items: center
      background: #666
      color: #f7f7f8
      font-size: 16px

    img
      width: 40px
      height: 40px
      border-radius: 75px

    .status
      height: 30px
      display: flex
      justify-content: center
      align-items: flex-start
      font-size: 11px
      font-weight: bold
      color: #666
      padding: 1px 0

    @media screen and (max-width: 700px)
      .name
        font-size: 10px
      .img
        width: 30px
        height: 30px
        min-width: 30px
        min-height: 30px
        font-size: 11px
      img
        width: 30px
        height: 30px
      .status
        font-size: 10px


