.messages-wrapper
  .message.attach-previous
    padding-top: 0
  .message.attach-next
    padding-bottom: 3px
  .spacer
    width: 40px
    height: 40px
  .message
    > .picture
      margin-bottom: -20px
      width: 2.5rem
      height: 2.5rem
    > .spacer
      margin-bottom: -20px
  .message.left
    a
      color: #666 !important
  .chat .message .content .bubble.attach-previous.left
    border-top-left-radius: 0
  .chat .message .content .bubble.attach-previous.right
    border-top-right-radius: 0
  .chat .message .content .bubble.attach-next.left
    border-bottom-left-radius: 0
  .chat .message .content .bubble.attach-next.right
    border-bottom-right-radius: 0
  .bubble.right a
    color: white
  .bubble.left a
    color: #fff

  .content-download
    display: flex
    align-items: center
    justify-content: space-between
  .content-size
    font-size: 10px
  .content-name
    font-size: 14px
  .content-icon
    min-width: 27px
    width: 27px
    font-size: 16px
    margin-left: 10px
